import { PayloadAction } from "@reduxjs/toolkit";
import { combineEpics, Epic } from "redux-observable";
import { from, ReplaySubject } from 'rxjs';
import { filter, map, mergeWith, switchMap, tap } from "rxjs/operators";
import { setUser, signin, signout } from "./userSlice";
import { UserData } from "@model/user-state";
import configureAws from 'aws-configurator';
import { authDomain } from "aws-website-config";
import { generateCodeVerifier } from "utils/oauth/codeVerifier";
import { handleAuthFlow, startSignInFlow } from "utils/oauth/flow";
import { RootState } from "redux/store";

const authSubject = new ReplaySubject<UserData>(1);
const config = configureAws();

if (!import.meta.env.SSR) {
  handleAuthFlow(authSubject);
}

const userEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(_ => false),
  mergeWith(authSubject.asObservable()),
  map(x => setUser(x))
)

const signinEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(action => action.type === signin.type),
  switchMap(_ => {
    return from(generateCodeVerifier(128)).pipe(switchMap(x => from(x.toCodeChallenge()).pipe(map(codeChallenge => ({...x, codeChallenge})))));
  }),
  map(({ value, method, codeChallenge }) => {
    startSignInFlow(value, method, codeChallenge);
    return undefined as unknown as PayloadAction;
  }),
  filter(_ => false)
);

const signoutEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(action => action.type === signout.type),
  tap(_ => {
    if (!import.meta.env.SSR) {
      window.location.href = `https://${authDomain}/logout?client_id=${config.aws_user_pools_web_client_id}&logout_uri=${encodeURIComponent(config.oauth.redirectSignOut)}`;
    }
  }),
  filter(_ => false)
)

export const authenticationEpic = combineEpics(signinEpic, signoutEpic, userEpic);


