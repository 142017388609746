import { Box, Container, ContainerTypeMap, Divider, styled, Typography } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { PropsWithChildren, ReactNode, useMemo } from "react";

export type MainContentProps = PropsWithChildren<{
  title: ReactNode | string,
  fullWidth?: boolean,
  noMargin?: boolean,
  fullScreen?: boolean,
}>;

export function MainContent({ title, children, fullWidth, noMargin, fullScreen }: MainContentProps) {
  const fullScreenProps = useMemo<DefaultComponentProps<ContainerTypeMap<{}, "div">>>(() => (fullScreen === true ? { maxWidth: false, disableGutters: true } : {}), [fullScreen]);

  const containerProps = useMemo<DefaultComponentProps<ContainerTypeMap<{}, "div">>>(() => ({
    ...(fullWidth === true
      ? {} 
      : { maxWidth: 'md' }),
    ...(noMargin === true
      ? { disableGutters: true } 
      : {}),
    ...fullScreenProps
  }), [fullScreenProps, fullWidth, noMargin]);

  return (
  <Container {...containerProps} >
      {!!title && <><Box sx={{textAlign: "center" }}>
      {typeof title === 'string' ? <Typography variant="h4" gutterBottom sx={{textAlign: "center"}}>
        {title}
      </Typography> : title}
    </Box>
    <Divider variant="middle" sx={{borderWidth: 2, borderRadius: 3}} /></>}
    <Box sx={{ minHeight: '100vh', mt: (!!title ? 20 : 10) + 'px' }} >
      {children}
    </Box>
  </Container>);
}

export const DrawerMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' && prop !== 'fullScreen' })<{
  open: boolean;
  drawerWidth: number;
  fullScreen?: boolean
}>(({ theme, open, drawerWidth, fullScreen }) => ({
  flexGrow: 1,
  padding: fullScreen === true ? 0 : theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));
