import { catchError, from, map, Observable, of } from "rxjs";
import { DeleteObjectCommandInput, DeleteObjectCommandOutput, DeleteObjectsCommandInput, DeleteObjectsCommandOutput, GetObjectCommandInput, GetObjectCommandOutput, ListObjectsV2CommandInput, ListObjectsV2CommandOutput, ListObjectsV2Output, ListObjectsV2Request, PutObjectCommandInput, PutObjectCommandOutput, PutObjectOutput, PutObjectRequest, S3 } from '@aws-sdk/client-s3';
import { HttpHandlerOptions } from "@smithy/types";
import { GetParameterCommandInput, GetParameterCommandOutput, SSM } from "@aws-sdk/client-ssm"
import { getAwsServiceClientConfig } from "utils/oauth/login-data";

// export type AwsMethodCallback<TOutput> = (err: AWSError, data: TOutput) => void;
export type AwsMethod<TRequest, TResponse> = (params: TRequest, options?: HttpHandlerOptions) => Promise<TResponse>;
export type AwsS3ListObjectMethod = AwsMethod<ListObjectsV2Request, ListObjectsV2Output>;
export type AwsS3PutObjectMethod = AwsMethod<PutObjectRequest, PutObjectOutput>;
export type AwsObservableResponse<TResponse> = {
  data?: TResponse,
  error?: any
}

export class AwsObservable {
  static _create<TRequest, TResponse>(awsMethod: AwsMethod<TRequest, TResponse>, bindTo: any): (params: TRequest) => Observable<AwsObservableResponse<TResponse>> {
    return (params: TRequest) => {
      const caller = awsMethod.bind(bindTo);
      const method$ = from(caller(params));
      const res$ = method$.pipe(
        map<TResponse, AwsObservableResponse<TResponse>>((data) => {
          return {
            data
          };
        }),
        catchError<AwsObservableResponse<TResponse>, Observable<AwsObservableResponse<TResponse>>>((err) => {
          return of({
            error: err
          });
        })
      );
      return res$;
    }
  }
}

export class S3ObservableFactory {
  private s3: S3;
  /**
   *
   */
  constructor() {
    this.s3 = new S3(getAwsServiceClientConfig());
  }

  listObjectsV2(): (params: ListObjectsV2Request) => Observable<AwsObservableResponse<ListObjectsV2CommandOutput>> {
    return AwsObservable._create<ListObjectsV2CommandInput, ListObjectsV2CommandOutput>(this.s3.listObjectsV2, this.s3);
  }

  putObject(): (params: PutObjectCommandInput) => Observable<AwsObservableResponse<PutObjectCommandOutput>> {
    return AwsObservable._create<PutObjectCommandInput, PutObjectCommandOutput>(this.s3.putObject, this.s3);
  }

  getObject(): (params: GetObjectCommandInput) => Observable<AwsObservableResponse<GetObjectCommandOutput>> {
    return AwsObservable._create<GetObjectCommandInput, GetObjectCommandOutput>(this.s3.getObject, this.s3);
  }

  deleteObject(): (params: DeleteObjectCommandInput) => Observable<AwsObservableResponse<DeleteObjectCommandOutput>> {
    return AwsObservable._create<DeleteObjectCommandInput, DeleteObjectCommandOutput>(this.s3.deleteObject, this.s3);
  }

  deleteObjects(): (params: DeleteObjectsCommandInput) => Observable<AwsObservableResponse<DeleteObjectsCommandOutput>> {
    return AwsObservable._create<DeleteObjectsCommandInput, DeleteObjectsCommandOutput>(this.s3.deleteObjects, this.s3);
  }
}


export class SsmServiceFactory {
  service: SSM;
  /**
   *
   */
  constructor() {
    this.service = new SSM(getAwsServiceClientConfig());
  }

  getParameter(): (params: GetParameterCommandInput) => Observable<AwsObservableResponse<GetParameterCommandOutput>> {
    return AwsObservable._create<GetParameterCommandInput, GetParameterCommandOutput>(this.service.getParameter, this.service);
  }
}
