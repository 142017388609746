import { RegionInputConfig } from "@smithy/config-resolver";
import { LoginDataStorageKey } from "app-constants";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Logins } from "@aws-sdk/credential-provider-cognito-identity";
import { AwsAuthInputConfig } from "@aws-sdk/middleware-signing";

import configureAws from "aws-configurator";
const config = configureAws();

export function clearLoginData() {
  localStorage.removeItem(LoginDataStorageKey);
}

export function setLoginData(logins: Logins) {
  localStorage.setItem(LoginDataStorageKey, JSON.stringify(logins));
}

export function getLoginData(): Logins | undefined {
  if (!import.meta.env.SSR) {
    const loginDataStr = localStorage.getItem(LoginDataStorageKey);
    const loginData = !!loginDataStr ? JSON.parse(loginDataStr) : undefined;
    return loginData;
  } else {
    return undefined;
  }
}

export function getAwsServiceClientConfig(): RegionInputConfig & AwsAuthInputConfig {
  const loginData = getLoginData();

  return {
    region: config.aws_cognito_region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: config.aws_cognito_region }, // Configure the underlying CognitoIdentityClient.
      identityPoolId: config.aws_cognito_identity_pool_id,
      logins: loginData
    })
  }
}
