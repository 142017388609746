import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useCallback, useState } from "react";

export type ProfileActionButtonProps = {
    userDisplayName: string,
    title?: string,
    signout: () => void
};

export function ProfileActionButton({ userDisplayName, signout }: ProfileActionButtonProps) {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
      }, []);
    
      const handleCloseNavMenu = useCallback(() => {
        setAnchorElNav(null);
      }, []);

    return (<><IconButton
        size="large"
        edge="end"
        aria-label={userDisplayName}
        // aria-controls={menuId}
        aria-haspopup="true"
        // onClick={handleProfileMenuOpen}
        color="inherit"
        onClick={handleOpenNavMenu}
      >
        <AccountCircle />
    </IconButton>
    <Menu
        id="profile-menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        >
        <MenuItem key="blogs" onClick={handleCloseNavMenu}>
            <IconButton size="large" aria-label='logout' onClick={signout} color="inherit">
                <LogoutIcon />
            </IconButton>
        </MenuItem>
    </Menu>
</>    
)
}